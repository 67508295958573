/*@import url('https://fonts.googleapis.com/css?family=Exo:400,700');
*/
*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
  
}
p, span {
  padding:0px;
  margin:0px;
}

.solution-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 10px; 
  background-color: #F5F5F5;
  min-height:500px;
}

.solution-card .solution-card {
  height: 100% !important;
}

.btn-border-1 {
  padding-left: 7px;
    padding-top: 10px;
    border: 1px solid black;
    padding-right: 7px;
    padding-bottom: 10px;
}
.btn-border-2 {
  padding-left: 3px;
    padding-top: 5px;
    border: 1px solid black;
    padding-right: 3px;
    padding-bottom: 5px;
}